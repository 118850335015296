import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyChsiQMXtsE8xqLBCAioUBsT35gEGS5DBU",
  authDomain: "foliage-floral.firebaseapp.com",
  databaseURL:
    "https://foliage-floral-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "foliage-floral",
  storageBucket: "foliage-floral.appspot.com",
  messagingSenderId: "547096816062",
  appId: "1:547096816062:web:1674b213ae347825b4f1ef",
  measurementId: "G-N6N84FBFSY",
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "FOLIAGE FLORAL"; //公司名稱
      },
      get regFormDomain() {
        return "https://foliage-floral.xyz/";
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = "#e8f2fa";

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = "#000";

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
